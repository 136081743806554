<template>
  <button
    class="disabled active w-full px-5 h-11 border-none rounded-lg font-semibold text-base leading-5 transition-all duration-300"
    :class="variants[variant]"
    :disabled="disabled"
  >
    {{ text }}
  </button>
</template>

<script setup lang="ts">
defineProps<{
  text: string
  variant: 'secondary' | 'main-outline' | 'main'
  disabled?: boolean
}>()

const variants = {
  main: '!bg-main hover:!bg-eastern-blue !text-white',
  'main-outline':
    '!text-main !bg-white !border !border-solid !border-main hover:!text-eastern-blue hover:!border-eastern-blue',
  secondary: 'bg-gray text-white',
}
</script>
<style scoped lang="postcss">
.active {
  @apply active:scale-95;
}
.disabled {
  @apply disabled:!bg-gray disabled:!text-white disabled:!border-none disabled:cursor-not-allowed;
}
</style>
